<template>
  <frame-auth width="440">
    <common-form @submit="login">
      <v-card-text>
        <p class="grey--text text-center">
          Please enter your patient credentials to log in.
        </p>

        <v-text-field
          id="login_username"
          v-model="credential.username"
          :rules="[
            $validators.isValid(),
            $validators.isEmail()
          ]"
          prepend-icon="mdi-account"
          placeholder="Email"
          solo
          flat
          outlined
          dense
        />

        <v-text-field
          id="login_password"
          v-model="credential.password"
          :rules="[$validators.isValid()]"
          type="password"
          prepend-icon="mdi-key-variant"
          placeholder="Password"
          solo
          flat
          outlined
          dense
        />

        <div class="d-flex align-center">
          <pp-send-activate activate />
          <v-spacer />
          <pp-send-activate />
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          class="pa-4"
          color="primary"
          rounded
          type="submit"
        >
          Log in
        </v-btn>
      </v-card-actions>
    </common-form>
  </frame-auth>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api'
import { auth } from 'ui-common'

export default {
  name: 'Login',
  layout: 'auth',
  setup: (props, ctx) => {
    const { root } = ctx

    const credential = reactive({
      username: root.$route.query.email || '',
      password: ''
    })

    const login = () => {
      auth.login(credential)
    }

    onMounted(() => {
      root.$router.push({ query: {} })
    })

    return {
      credential,
      login
    }
  }
}
</script>
